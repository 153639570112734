<script setup lang='ts'>
import type { FormInst, FormItemRule } from 'naive-ui'
import { checkPhone } from '@qctsw/utils'
import { logicLoginSubmit, logicPhoneLogin } from '@/logic/user/login'
import { captchaImgApi } from '~/server'
import type { CaptchaImg } from '~/server/types'

const rules = {
  phone: [
    {
      validator: (_rule: FormItemRule, value: string) => {
        if (!value)
          return new Error('请输入手机号')
        if (!checkPhone(value) || !value)
          return new Error('请输入正确的手机号')
        return true
      },
      trigger: 'blur',
    },
  ],
  verifycode: [
    {
      required: true,
      message: '请输入验证码',
      trigger: 'blur',
    },
  ],
}
const phoneInfo = ref({
  phone: '',
  verifycode: '',
  imgCode: '',
})
const formRef = ref<FormInst | null>(null)
const loading = ref(false)
async function loginSubmit() {
  logicLoginSubmit({
    formRef,
    loading,
    fn: () => logicPhoneLogin({
      phone: phoneInfo.value.phone,
      verifycode: phoneInfo.value.verifycode,
    }),
  })
}

const captchaData = ref<CaptchaImg>({
  img: '',
  captchaEnabled: true,
  uuid: '',
})

onMounted(() => {
  getCaptcha()
})

function getCaptcha() {
  captchaImgApi().then((res) => {
    phoneInfo.value.imgCode = ''
    captchaData.value = res.data
  })
}
</script>

<template>
  <div class="pb-sm text-slate-5 text-3 pb-sm">
    <i class="i-ph:warning-circle-fill mr-1 text-blue-5" />
    <span>如手机号未被注册将直接注册新用户</span>
  </div>
  <NForm
    ref="formRef"
    :label-width="80"
    :model="phoneInfo"
    :rules="rules"
    size="large"
  >
    <NFormItem path="phone" :show-label="false">
      <NInput
        v-model:value="phoneInfo.phone"
        class="bg-slate-2! rounded-md"
        size="large"
        placeholder="手机号"
      />
    </NFormItem>

    <NFormItem path="imgCode" :show-label="false" class="mb-2">
      <div class="flex items-center justify-between w-full">
        <NInput
          v-model:value="phoneInfo.imgCode"
          size="large"
          class=" bg-slate-2! rounded-md"
          placeholder="请输入图形验证码"
        />
        <img class="w-30 ml-3  " :src="`data:image/png;base64,${captchaData.img}`" @click="getCaptcha">
      </div>
    </NFormItem>
    <NFormItem path="verifycode" :show-label="false" class="mb-2">
      <div class="flex items-center justify-between w-full">
        <NInput
          v-model:value="phoneInfo.verifycode"
          size="large"
          class=" bg-slate-2! rounded-md"
          placeholder="请输入验证码"
        />
        <UtilVerificationCode :refresh="getCaptcha" :uuid="captchaData.uuid" :img-code="phoneInfo.imgCode" :phone="phoneInfo.phone" class="ml-6 btn-primary flex-shrink-0 py-2" />
      </div>
    </NFormItem>
  </NForm>
  <button
    class="block w-full text-white py-2 rounded-md"
    :class="[loading ? 'cursor-not-allowed bg-blue-3' : 'bg-blue-5 cursor-pointer']"
    :disabled="loading"
    @click="loginSubmit"
  >
    <i v-if="loading" class="i-eos-icons:loading mr-1 vertical-icon" />
    <span v-else>登录</span>
  </button>
</template>

<style lang='scss' scoped>

</style>
